<style lang="scss" scoped>
.el-form-item {
}
.form-line-right {
  float: right;
  margin-top: 10px;
  width: 162px;
}
</style>
<!-- 验收确认 -->
<template>
  <div class="progressformchild">
    <div>
      <el-form
        ref="progressFormData"
        :rules="rules"
        label-width="110px"
        :model="progressFormData"
      >
        <el-form-item label="操作人员" prop="hasSelectedSh">
          <!-- <el-radio-group v-model="progressFormData.operuser">
            <el-radio :label="0">发起人</el-radio>
            <el-radio :label="1">其他人员</el-radio>
          </el-radio-group> -->
          <el-checkbox label="1" v-model="progressFormData.operuser">发起人</el-checkbox>
          <el-checkbox
            label="1"
            @change="$forceUpdate()"
            v-model="progressFormData.operuserother"
            >其他人员</el-checkbox
          >
        </el-form-item>
        <el-form-item label="" v-if="progressFormData.operuserother">
          <el-radio-group v-model="progressFormData.examinetype">
            <el-radio :label="0">部门</el-radio>
            <el-radio :label="1">角色</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="选择部门"
          v-if="progressFormData.examinetype == 0 && progressFormData.operuserother"
          prop="branch_id"
        >
          <el-select
            style="width: 170px; float: left"
            v-model="progressFormData.branch_id"
            @change="
              progressFormData.is_branch_leader = 1;
              $forceUpdate();
            "
            placeholder="请选择部门"
          >
            <el-option
              v-for="(item, index) in branchList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-radio-group
            class="form-line-right"
            @change="$forceUpdate()"
            v-model="progressFormData.is_branch_leader"
          >
            <el-radio :label="1">部门领导</el-radio>
            <el-radio :label="2">所有</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="选择角色"
          v-if="progressFormData.examinetype == 1"
          prop="front_role_id"
        >
          <el-select
            style="width: 100%"
            v-model="progressFormData.front_role_id"
            placeholder="请选择角色"
          >
            <el-option
              v-for="(item, index) in roleList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-radio-group
            class="form-line-right"
            v-model="progressFormData.is_branch_leader"
          >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发起人">
          <el-checkbox label="1" v-model="progressFormData.examinerejectdo"
            >是否需要发起人审核</el-checkbox
          >
        </el-form-item>

        <el-form-item label="填写备注">
          <el-radio-group v-model="progressFormData.examinerejectremark">
            <el-radio :label="0">必要</el-radio>
            <el-radio :label="1">非必要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传附件">
          <el-radio-group v-model="progressFormData.examinerejectanno">
            <el-radio :label="0">必要</el-radio>
            <el-radio :label="1">非必要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-divider>审核拒绝</el-divider>
        <el-form-item label="执行操作" prop="examinerejectdohandler">
          <el-select
            style="width: 100%"
            v-model="progressFormData.examinerejectdohandler"
            placeholder="请选择操作"
          >
            <el-option
              :disabled="currentStepList.length == 0 && item.id == 0"
              v-for="(item, index) in doList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选择重置节点"
          prop="examinerejectdoreset"
          v-if="progressFormData.examinerejectdohandler == 0"
        >
          <el-select
            style="width: 100%"
            v-model="progressFormData.examinerejectdoreset"
            placeholder="请选择操作"
          >
            <el-option
              v-for="(item, index) in currentStepList"
              :key="index"
              :label="`步骤${item.step}:${item.name}`"
              :value="item.step"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider>审核通过</el-divider>
        <el-form-item label="是否结束" prop="examineresoveisover">
          <el-radio-group v-model="progressFormData.examineresoveisover">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否需要评论">
          <el-checkbox v-model="progressFormData.examineresovedo"
            >是否需要发起人评论</el-checkbox
          >
        </el-form-item>
        <el-divider>其他消息推送</el-divider>
        <el-form-item label="推送选择">
          <el-checkbox label="1" v-model="progressFormData.prevstep"
            >上一节点办理人</el-checkbox
          >
          <el-checkbox label="1" v-model="progressFormData.creatuser">发起人</el-checkbox>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getBaseConfig } from "@api/common";
import { getBranchList } from "@api/branch";
import { getFrontRoleList } from "@api/frontRole";
export default {
  name: "progressformcom5",
  props: [
    "branchList",
    "roleList",
    "rules",
    "currentStepList",
    "currentStep",
    "progressNodeData",
  ],
  data() {
    return {
      progressFormData: {},
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {},
  async mounted() {
    this.progressFormData = {
      examinerejectdo: false,
      examinerejectremark: 1,
      examinerejectanno: 1,
      examineresoveremark: 1,
      examineresoveanno: 1,
      examineresoveisover: 0,
      operuser: true,
      operuserother: false,
    };
    if (this.progressNodeData && this.progressNodeData.id && this.progressNodeData.id > 0)
      this.progressFormData = this.alanayChildFormData(this.progressNodeData);
    if (this.progressNodeData.is_start_user == 1) this.progressFormData.operuser = true;
    if (this.progressNodeData.is_other == 1) this.progressFormData.operuserother = true;
    if (this.progressNodeData.is_other == 1) {
    }
    this.$forceUpdate();
    console.log("progressFormData", this.progressFormData);
  },
  methods: {
    validateForm: function () {
      if (this.progressFormData.operuser == 1 || this.progressFormData.operuserother == 1)
        this.progressFormData.hasSelectedSh = true;
      else delete this.progressFormData.hasSelectedSh;
      console.log('this.progressFormData.hasSelectedSh',this.progressFormData.hasSelectedSh)
      return new Promise((resolve, reject) => {
        this.$refs["progressFormData"].validate(async (valid) => {
          //数据清洗
          let newFormData = { no_pass_config: {}, pass_config: {} };
          newFormData.examinetype = this.progressFormData.examinetype;
          if (this.progressFormData.operuserother) {
            newFormData.is_other = 1;
          } else {
            newFormData.is_other = 2;
          }
          if (this.progressFormData.operuser) {
            newFormData.is_start_user = 1;
          } else {
            newFormData.is_start_user = 2;
          }
          if (this.progressFormData.operuser) {
            if (this.progressFormData.examinetype == 3) {
              newFormData.is_assign_user = 1;
            } else {
              if (this.progressFormData.examinetype == 0) {
                newFormData.branch_id = this.progressFormData.branch_id;
                newFormData.is_branch_leader =
                  this.progressFormData.is_branch_leader == 1 ? 1 : 2;
              } else newFormData.front_role_id = this.progressFormData.front_role_id;
            }
          }
          newFormData.no_pass_config.remarks = ["require", "no_require", "no_need"][
            this.progressFormData.examinerejectremark
          ];
          newFormData.pass_config.remarks = ["require", "no_require", "no_need"][
            this.progressFormData.examinerejectremark
          ];
          newFormData.no_pass_config.annex = ["require", "no_require", "no_need"][
            this.progressFormData.examinerejectanno
          ];
          newFormData.pass_config.annex = ["require", "no_require", "no_need"][
            this.progressFormData.examinerejectanno
          ];
          newFormData.no_pass_config.push_msg = "issue_user";
          newFormData.no_pass_config.do =
            this.progressFormData.examinerejectdohandler == 0
              ? "retrace_" + this.progressFormData.examinerejectdoreset
              : this.progressFormData.examinerejectdohandler;

          if (this.progressFormData.examineresovedo == true) {
            newFormData.pass_config.comment = "need";
          }
          if (this.progressFormData.examinerejectdo == true) {
            newFormData.pass_config.comment = "need";
            newFormData.no_pass_config.comment = "need";
          }
          newFormData.pass_config.do =
            this.progressFormData.examineresoveisover == 1 ? "do_7" : "do_next";
          newFormData.pass_config.push_msg = "next_user";
          newFormData.other_msg_push = [];
          if (this.progressFormData.prevstep)
            newFormData.other_msg_push.push("last_user");
          if (this.progressFormData.creatuser)
            newFormData.other_msg_push.push("issue_user");
          resolve({
            status: valid,
            data: newFormData,
          });
        });
      });
    },
  },
};
</script>
