<style lang="scss" scoped>
.el-form-item {
}
.form-line-right {
  float: right;
  margin-top: 10px;
}
</style>

<template>
  <div class="progressform">
    <el-dialog
      title="流程节点配置"
      width="500px"
      @close="closeDialogEventHandler"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="progressFormData"
        label-width="110px"
        :rules="rules"
        :model="progressFormData"
      >
        <el-form-item label="节点类型" prop="node">
          <el-select
            :disabled="isEditMode"
            v-model="progressFormData.node"
            placeholder="请选择节点类型"
          >
            <el-option
              v-for="(item, index) in progressTypeList"
              :key="index"
              :label="item.name"
              :value="item.node"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="节点名称" prop="name">
          <el-input v-model="progressFormData.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="当前步骤" prop="step">
          <el-input
            v-model="progressFormData.step"
            @change="$forceUpdate()"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <progressformcomtype1com
          ref="progressformcomtype1com"
          :rules="rules"
          v-if="progressFormData.node == 1"
          :branchList="branchList"
          :roleList="roleList"
          :progressNodeData="progressNodeData"
          :currentStepList="currentStepList"
          :currentStep="currentStep"
        ></progressformcomtype1com>
        <progressformcomtype2com
          ref="progressformcomtype2com"
          :rules="rules"
          v-if="progressFormData.node == 2"
          :branchList="branchList"
          :roleList="roleList"
          :progressNodeData="progressNodeData"
          :currentStepList="currentStepList"
          :currentStep="currentStep"
        ></progressformcomtype2com>
        <progressformcomtype3com
          ref="progressformcomtype3com"
          :rules="rules"
          v-if="progressFormData.node == 3"
          :branchList="branchList"
          :roleList="roleList"
          :currentStepList="currentStepList"
          :progressNodeData="progressNodeData"
          :currentStep="currentStep"
        ></progressformcomtype3com>
        <progressformcomtype4com
          ref="progressformcomtype4com"
          :rules="rules"
          v-if="progressFormData.node == 4"
          :branchList="branchList"
          :roleList="roleList"
          :currentStepList="currentStepList"
          :progressNodeData="progressNodeData"
          :currentStep="currentStep"
        ></progressformcomtype4com>
        <progressformcomtype5com
          ref="progressformcomtype5com"
          :rules="rules"
          v-if="progressFormData.node == 5"
          :branchList="branchList"
          :roleList="roleList"
          :currentStepList="currentStepList"
          :progressNodeData="progressNodeData"
          :currentStep="currentStep"
        ></progressformcomtype5com>
        <progressformcomtype6com
          ref="progressformcomtype6com"
          :rules="rules"
          v-if="progressFormData.node == 6"
          :progressNodeData="progressNodeData"
          :branchList="branchList"
          :roleList="roleList"
          :currentStepList="currentStepList"
          :currentStep="currentStep"
        ></progressformcomtype6com>
        <progressformcomtype7com
          ref="progressformcomtype7com"
          :rules="rules"
          v-if="progressFormData.node == 7"
          :branchList="branchList"
          :progressNodeData="progressNodeData"
          :roleList="roleList"
          :currentStepList="currentStepList"
          :currentStep="currentStep"
        ></progressformcomtype7com>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStep" :loading="btnSubmitLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getBaseConfig } from "@api/common";
import { getBranchList } from "@api/branch";
import {
  workOrderProgressStepAdd,
  workOrderProgressStepList,
  workOrderProgressStepDetail,
  workOrderProgressStepEdit,
} from "@api/workOrderProgress";
import { getFrontRoleList } from "@api/frontRole";
import progressformcomtype1com from "@/components/progressformcom_type1com";
import progressformcomtype2com from "@/components/progressformcom_type2com";
import progressformcomtype3com from "@/components/progressformcom_type3com";
import progressformcomtype4com from "@/components/progressformcom_type4com";
import progressformcomtype5com from "@/components/progressformcom_type5com";
import progressformcomtype6com from "@/components/progressformcom_type6com";
import progressformcomtype7com from "@/components/progressformcom_type7com";
export default {
  name: "progressformcom",
  props: [],
  /**
   * 组件
   */
  components: {
    progressformcomtype1com,
    progressformcomtype2com,
    progressformcomtype3com,
    progressformcomtype4com,
    progressformcomtype5com,
    progressformcomtype6com,
    progressformcomtype7com,
  },
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: 120,
      work_order_id: 0,
      currentStep: 0,
      progressFormData: {
        step: 0,
      },
      progressTypeList: [],
      //节点列表
      currentStepList: [],
      //表单校验规则
      rules: {
        name: [{ required: true, message: "请输入节点名称", trigger: "blur" }],
        examinerejectdo: [
          {
            required: true,
            message: "请选择是否需要发起人评论",
            trigger: "blur",
          },
        ],
        node: [
          {
            required: true,
            message: "请选择节点类型",
            trigger: "blur",
          },
        ],
        examinerejectdohandler: [
          {
            required: true,
            message: "请选择审核拒绝之后的执行操作",
            trigger: "blur",
          },
        ],
        examinerejectdoreset: [
          {
            required: true,
            message: "请选择要重置的节点",
            trigger: "blur",
          },
        ],
        branch_id: [
          {
            required: true,
            message: "请选择部门",
            trigger: "blur",
          },
        ],
        front_role_id: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
        examinetype: [
          {
            required: true,
            message: "请选择审核人员",
            trigger: "blur",
          },
        ],
        hasSelectedSh: [
          {
            required: true,
            message: "请选择操作人员",
            trigger: "blur",
          },
        ],
      },
      btnSubmitLoading: false,
      //当前节点的表单数据（编辑模式）
      progressNodeData: {},
      //是否是编辑模式
      isEditMode: false,
      //部门列表
      branchList: [],
      //角色列表
      roleList: [],
      //是否更新列表
      isUpdateList: false,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {},
  async mounted() {
    let res = await getBaseConfig();
    for (let v in res.data.process_type) {
      this.progressTypeList.push({
        node: res.data.process_type[v].node,
        name: res.data.process_type[v].name,
        data: res.data.process_type[v],
      });
    }
    this.branchList = (await getBranchList()).data;
    this.roleList = (await getFrontRoleList()).data;
  },
  beforeDestroy() {
    console.log(222);
  },
  close() {},
  methods: {
    /**
     * 保存节点设置
     */
    saveStep: async function () {
      //子集表单验证没通过
      this.$refs["progressFormData"].validate(async (valid) => {
        if (valid) {
          let validateRes = await this.$refs[
            `progressformcomtype${this.progressFormData.node}com`
          ].validateForm();
          if (!validateRes.status) return false;
          else {
            this.progressFormData.work_order_id = this.work_order_id;
            this.progressFormData = Object.assign(
              this.progressFormData,
              validateRes.data
            );
          }
          if (this.progressFormData.examinetype == 0) {
            this.progressFormData.front_role_id = "";
            this.progressFormData.is_assign_user = "";
          } else if (this.progressFormData.examinetype == 1) {
            this.progressFormData.branch_id = "";
            this.progressFormData.is_branch_leader = "";
            this.progressFormData.is_assign_user = "";
          } else {
            this.progressFormData.branch_id = "";
            this.progressFormData.is_branch_leader = "";
            this.progressFormData.front_role_id = "";
          }
          //   return;
          this.btnSubmitLoading = true;
          var res;
          if (this.progressFormData && this.progressFormData.id > 0)
            //更新
            res = await workOrderProgressStepEdit(this.progressFormData);
          //新增
          else res = await workOrderProgressStepAdd(this.progressFormData);
          this.btnSubmitLoading = false;
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.isUpdateList = true;
            // 请求后，不论是否成功，调用方法关闭弹窗
            this.handleCancle();
          }
        }
      });
    },
    showProgressFormDailog: async function (work_order_id, progressNodeId = 0) {
      this.work_order_id = work_order_id;
      this.progressNodeData = this.progressFormData = {};
      this.currentStepList =
        (await workOrderProgressStepList({ work_order_id: this.work_order_id })).data ||
        [];
      this.currentStep =
        this.currentStepList.length > 0 ? this.currentStepList.length + 1 : 1;
      if (progressNodeId > 0) {
        this.isEditMode = true;
        this.progressNodeData = (await workOrderProgressStepDetail(progressNodeId)).data;
        //给progressFormData赋值
        this.progressFormData = this.progressNodeData;
      } else {
        this.progressFormData.step = this.currentStep;
        this.isEditMode = false;
      }
      this.dialogFormVisible = true;
    },
    handleCancle: function () {
      this.dialogFormVisible = false;
      this.closeDialogEventHandler();
    },
    closeDialogEventHandler: function () {
      console.log("22");
      this.$refs.progressFormData.clearValidate();
      this.$emit("closeDialogEventHandler", this.isUpdateList);
    },
  },
};
</script>
