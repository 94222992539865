<style lang="scss" scoped>
.progress {
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ececec;
  h3 {
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #ececec;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-weight: 200;
    padding-top: 5px;
  }
  div {
    text-align: left;
    overflow: hidden;
    position: relative;
    p {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      width: 96%;
      padding: 0 2% 0 2%;
      color: #333333;
      :last-child {
        float: right;
      }
    }
    &:hover div {
      bottom: 0px;
    }
    div {
      position: absolute;
      bottom: 0;
      left: 0;
      transition-duration: 0.5s;
      padding: 10px 0 10px 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      text-align: center;
      bottom: -40px;
      i {
        display: inline-block;
        color: #ffffff;
        margin: 0 10px 0 10px;
        cursor: pointer;
        opacity: 0.9;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>

<template>
  <div class="progress">
    <h3>第{{ progressData.step }}步：{{ progressData.name }}</h3>
    <div>
      <p>
        <span>节点类型</span
        ><span>{{
          this.progressTypeList.find((a) => a.node == progressData.node).name
        }}</span>
      </p>
      <p>
        <span>审核人</span
        ><span>{{
          progressData.branch_name || progressData.front_role_name || "指定人员"
        }}</span>
      </p>
      <p>
        <span>备注</span
        ><span>{{
          progressData.no_pass_config.comment == "need" ||
          progressData.pass_config.comment == "need"
            ? "需要"
            : "不需要"
        }}</span>
      </p>
      <p>
        <span>附件</span
        ><span>{{
          progressData.no_pass_config.annex == "need" ||
          progressData.pass_config.annex == "need"
            ? "需要"
            : "不需要"
        }}</span>
      </p>
      <p>
        <span>消息推送</span><span>{{ pushUser }}</span>
      </p>
      <div>
        <i class="el-icon-edit" title="编辑" @click="editNode"></i>
        <i class="el-icon-delete" title="删除" @click="deleteNode"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "progresscom",
  props: ["progressData", "progressTypeList"],
  data() {
    return {
      pushUser: "",
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {},
  async mounted() {
    if (this.progressData.other_msg_push) {
      if (this.progressData.other_msg_push.indexOf("last_user") >= 0)
        this.pushUser += `上一节点办理人,`;
      if (this.progressData.other_msg_push.indexOf("issue_user") >= 0)
        this.pushUser += `发起人,`;
    } else {
      this.pushUser += `无,`;
    }
    if (this.pushUser.length > 0)
      this.pushUser = this.pushUser.substring(0, this.pushUser.length - 1);
  },
  methods: {
    //编辑
    editNode: function () {
      this.$emit("emitNodeEventHandler", this.progressData);
    },
    //删除
    deleteNode: function () {
      this.$confirm("此操作将永久删除该节点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        /** 确认触发 */
        .then(async () => {
          this.$emit("deleteNodeEventHandler", this.progressData.id);
        })
        /** 取消触发 */
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除操作",
          });
        });
    },
  },
};
</script>
