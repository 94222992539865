<style lang="scss" scoped>
.page-child-contianer {
  overflow: hidden;
  display: flex;
  .progress_left {
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    float: left;
    color: #000000;
    h3 {
      font-size: 16px;
      font-weight: 200;
      text-align: center;
      line-height: 22px;
      border-bottom: 1px solid #ececec;
      padding-bottom: 5px;
    }
    p {
      margin-top: 15px;
      font-size: 14px;
      color: #333333;
      :last-child {
        float: right;
      }
    }
  }
  .progress_right {
    width: 84%;
    float: left;
    margin-left: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    text-align: center;
    overflow: hidden;
    .add_btn {
      width: 150px;
      padding: 57px 0 56px 0;
      text-align: center;
      margin-bottom: 20px;
      line-height: 20px;
      border: 1px solid #ececec;
      border-radius: 5px;
      margin-right: 20px;
      float: left;
      cursor: pointer;
      i {
        font-size: 40px;
        color: #666666;
      }
      p {
        font-size: 14px;
      }
      &:hover {
        border-color: #409eff;
      }
      &:hover i {
        color: #409eff;
      }
      &:hover p {
        color: #409eff;
      }
    }
    .progress {
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>

<template>
  <section class="page-child-contianer" v-loading="loading">
    <div class="progress_left">
      <h3>{{ workOrderObj.name }}(流程配置）</h3>
      <p>
        <span>流程总步骤</span>
        <span>{{ workOrderProgressList.length }}步</span>
      </p>
      <p>
        <span>流程步骤</span>
        <span></span>
      </p>
      <p v-for="(item, index) in workOrderProgressList" :key="index">
        <span style="margin-left: 20px">第{{ item.step }}步</span>
        <span>{{ item.name }}</span>
      </p>
      <p>
        <span>流程闭环</span>
        <span
          v-if="workOrderProgressList.filter((a) => a.node == 7).length > 0"
          style="color: #409eff"
          >已闭环</span
        >
        <span v-else style="color: #f56c6c">无结束环节</span>
      </p>
      <el-button type="primary" style="width:60%;margin-left:20%;margin-top:30px;" @click="$router.push('/workorderprogress/index/list')"
        >返回</el-button
      >
    </div>
    <div class="progress_right">
      <progresscom
        v-for="(item, index) in workOrderProgressList"
        :key="index"
        :progressData="item"
        @deleteNodeEventHandler="deleteNodeEventHandler"
        @emitNodeEventHandler="emitNodeEventHandler"
        :progressTypeList="progressTypeList"
      ></progresscom>
      <div class="add_btn" v-if="!isWorkOrderOver" @click="showProgressForm">
        <i class="el-icon-plus"></i>
        <p>添加流程</p>
      </div>
    </div>
    <progressformcom
      ref="progressformcom"
      @closeDialogEventHandler="closeDialogEventHandler"
    ></progressformcom>
  </section>
</template>

<script>
import progresscom from "@/components/progresscom";
import progressformcom from "@/components/progressformcom";
import { getBaseConfig } from "@api/common";
import {
  workOrderProgressDetail,
  workOrderProgressStepList,
  workOrderProgressStepDel,
} from "@api/workOrderProgress";
import { getWorkOrderList } from "@api/workOrder";
export default {
  name: "progressv",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {
    progresscom,
    progressformcom,
  },
  data() {
    return {
      id: null, //修改用户id
      imageUrl: "",
      progressTypeList: [],
      isWorkOrderOver: false,
      loading: false,
      formData: {
        sort: 200,
        checkList: [],
      }, //表单存储数据
      workOrderObj: {},
      //流程列表
      workOrderProgressList: [],
      rules: {
        name: [{ required: true, message: "请输入工单名称", trigger: "blur" }],
      }, //表单校验规则
    };
  },
  watch: {
    workOrderProgressList() {
      if (!this.workOrderProgressList || this.workOrderProgressList.length == 0)
        this.isWorkOrderOver = false;
      if (this.workOrderProgressList[this.workOrderProgressList.length - 1].node == 7)
        this.isWorkOrderOver = true;
      else this.isWorkOrderOver = false;
      this.$forceUpdate();
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      let res = await workOrderProgressDetail(this.id);
      this.workOrderObj = res.data;
    } else this.$message.error("工单无效");
  },
  async mounted() {
    this.getData();
    let res = await getBaseConfig();
    for (let v in res.data.process_type) {
      this.progressTypeList.push({
        node: res.data.process_type[v].node,
        name: res.data.process_type[v].name,
        data: res.data.process_type[v],
      });
    }
  },
  methods: {
    //编辑节点
    emitNodeEventHandler: function (progressNodeData) {
      this.$refs.progressformcom.showProgressFormDailog(this.id, progressNodeData.id);
    },
    //节点删除
    deleteNodeEventHandler: async function (id) {
      let res = await workOrderProgressStepDel({ id: id });
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }
      this.getData();
    },
    showProgressForm: function () {
      this.$refs.progressformcom.showProgressFormDailog(this.id);
    },
    closeDialogEventHandler: function (isUpdate) {
      if (isUpdate) this.getData();
    },
    getData: async function () {
      this.loading = true;
      this.workOrderProgressList = (
        await workOrderProgressStepList({
          work_order_id: this.id,
        })
      ).data;
      console.log("this.workOrderProgressList", this.workOrderProgressList);
      this.loading = false;
    },
  },
};
</script>
