<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px !important;
}
.form-line-right {
  float: right;
  margin-top: 10px;
  width: 162px;
}
</style>
<!-- 到达确认 -->
<template>
  <div class="progressformchild">
    <div>
      <el-form
        ref="progressFormData"
        :rules="rules"
        label-width="110px"
        :model="progressFormData"
      >
        <el-form-item label="发起人评论">
          <el-checkbox v-model="progressFormData.examinerejectdo"
            >是否需要发起人评论</el-checkbox
          >
        </el-form-item>
        <el-form-item label="上传附件">
          <el-radio-group v-model="progressFormData.examinerejectanno">
            <el-radio :label="0">必要</el-radio>
            <el-radio :label="1">非必要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getBaseConfig } from "@api/common";
import { getBranchList } from "@api/branch";
import { getFrontRoleList } from "@api/frontRole";
export default {
  name: "progressformcom7",
  props: [
    "branchList",
    "roleList",
    "rules",
    "currentStepList",
    "currentStep",
    "progressNodeData",
  ],
  data() {
    return {
      progressFormData: {},
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {},
  async mounted() {
    this.progressFormData = {
      examinerejectdo: false,
      examinerejectremark: 1,
      examinerejectanno: 1,
      examineresoveremark: 1,
      examineresoveanno: 1,
      examineresoveisover: 0,
    };
    if (this.progressNodeData && this.progressNodeData.id && this.progressNodeData.id > 0)
      this.progressFormData = this.alanayChildFormData(this.progressNodeData);
    this.$forceUpdate();
  },
  methods: {
    validateForm: function () {
      return new Promise((resolve, reject) => {
        this.$refs["progressFormData"].validate(async (valid) => {
          //数据清洗
          let newFormData = { no_pass_config: {}, pass_config: {} };
          newFormData.no_pass_config.comment =
            this.progressFormData.examinerejectdo == true ? "need" : "no_need";
          newFormData.no_pass_config.annex = ["require", "no_require", "no_need"][
            this.progressFormData.examinerejectanno
          ];
          newFormData.pass_config.annex = ["require", "no_require", "no_need"][
            this.progressFormData.examinerejectanno
          ];
          newFormData.no_pass_config.push_msg = "issue_user";
          newFormData.no_pass_config.do = "do_7";
          newFormData.pass_config.do = "do_7";
          newFormData.pass_config.comment = newFormData.no_pass_config.comment;
          newFormData.pass_config.annex = newFormData.no_pass_config.annex;
          newFormData.pass_config.push_msg = newFormData.no_pass_config.push_msg;

          resolve({
            status: valid,
            data: newFormData,
          });
        });
      });
    },
  },
};
</script>
